.toggle-btn {
  cursor: pointer;
  display: block;
  position:fixed; /* Use 'absolute' or 'relative' based on your layout */
  top: -113px;
  right: 70px; /* Adjust as needed */
  z-index: 1001; /* Increase the z-index */
  
}

.line {
  width: 30px;
  height: 3px;
  background-color: #97f137;
  margin: 6px 0;
  transition: 0.4s;
}
.faculty-sidebar {
    height: 100vh; /* 100% of the viewport height */
    position: fixed;
    top: 0px;
    left: 0;
    width: 100px;
    /* background-color: #fcfafa; */
    background-color: #f7f7f7;
    z-index: 10;
   
    padding: 20px;
    margin: 0px;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);
    overflow-y: auto; /* Add scroll if the content is taller than the sidebar */
    transition: transform 0.4s ease-in-out;
    transform: translateX(0);
  }

  .faculty-sidebar.closed {
    transform: translateX(-100%);
  }
  
  .faculty-sidebar-icon{
    height: 50px;
  }

  .faculty-sidebar ul {
    list-style: none;
    padding: 0px;
    margin-top: 45px;
  }
  
  .faculty-sidebar-item {
    padding: 12px;
    margin-bottom: 14px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-left: -18px; /* Adjusted left margin on hover */
    margin-right: -18px;
    color: black;
  }
  
  .faculty-sidebar-item.selected {
    background-color: #97f137;
  }

  .faculty-sidebar-item.selected:hover {
    background-color: #97f137;
  }

  .faculty-icon-title{
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    text-align: center; /* Center text within the container */
  }

  .faculty-icon-title span {
    font-size: 14px; /* Adjust the font size as needed */
  }


  .faculty-sidebar-item:hover {
    background-color: #dfecd0;
    cursor: pointer;
    margin-left: -18px; /* Adjusted left margin on hover */
  margin-right: -18px;  
  }

  .faculty-sidebar-item:hover svg {
   color: rgb(61, 60, 60); /* Change to your desired icon color on hover */
  }

  @media (min-width: 768px) {
    .toggle-btn {
      display: none;
    }
  }