/* ApiUsageDetails.css */

.api-usage-details-container {
    width: 100%;
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid lawngreen;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    overflow-x: hidden;
    overflow-y: auto;
}

.api-usage-details-container h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
}

.usage-details-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.copy-button {
    padding: 5px ;
    height: 25px;
    border: none;
    border-radius: 12px;
    background-color: #9bdf46;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 12px;
}

.copy-button:hover {
    background-color: #7fb83a;
}

.code-block {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
    overflow-x: auto;
    font-family: 'Courier New', Courier, monospace;
    width: 65%;
}

#cpy-btn-1{
    margin-left: 130px;
}

#cpy-btn-2{
    margin-left: 80px;
}


@media (max-width: 768px) {
    .api-usage-details-container {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        margin-left: 12px;
        width: 83%;
    }

    .usage-details-item {
        font-size: 14px;
    }

    .code-block {
        width: 99%;
        display: flex;
        font-size: 12px;
        margin-left: -7px;
    }

    #cpy-btn-1{
        margin-left: 170px;
    }
    
    #cpy-btn-2{
        margin-left: 125px;
    }

}
