/* TrainingsPage.css */

/* fonts */
@font-face {
  font-family: 'OpenSansextrabold';
  src: url('./fonts/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.lecture-attachment-type-code_display {
  background-color: #272822;
  color: #f8f8f2;
  border: 1px solid #49483e;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  font-family: 'Fira Code', monospace;
  font-size: 14px;
  line-height: 1.4;
  white-space: pre-wrap;
}

.ring-mark {
  margin-right: 5px; /* spacing between the cross mark and the lesson name if needed */
  display: inline;
}

.lesson.completed .ring-mark {
  display: none; /* Hide the cross mark for completed lessons */
}


.tick-mark {
  margin-right: 5px; /* spacing between the tick mark and the lesson name if needed */
  display: inline;
}

.tick-icon{
  height: 30px;
  width: 30px;
}

.ring-icon{
  height: 30px;
  width: 30px; 
}

.lesson-item {
  display: flex;
  align-items: center; /* Center items vertically */
}
.lesson-item:hover{
  background-color: #2e2e2ed5;
}

.code-box {
    background-color: #f7f7f9;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-family: 'Monaco', monospace;
    white-space: pre-wrap;
}

code{
  white-space: pre-wrap !important;
}


body, html {
  margin: 0;
  padding: 0;
}

.page-container {
  display: flex;
  background-color: #1D2531;
  height: 100vh; /* Make sure the container takes up the full viewport height */
  overflow: hidden;  
}

.trainings-page {
  display: flex;
  background-color: #1D2531;
}

.sidebar {
  flex: 1;
  background-color: #1D2531;
  padding: 0;
  overflow-y: hidden; /*disable vertical scrolling for the sidebar */
  max-height: 100vh; /* Set a maximum height for the sidebar */
  margin: -2px;
  overflow-x: hidden;
  position: relative;
}
.sidebar-header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #1D2531;
  padding: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  border: none;
}

.main-header::after,
.sidebar-header::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 110%;
  width: 1px;
}

.main-header::after {
  left: -1px;
}

.sidebar-header::after {
  left: calc(100% - 1px);
}

.home-icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin: 10px;
}

.sidebar-content{
  overflow-y: hidden;
  height: calc(100% - 60px); /* Adjust based on your header and other elements height */
  padding-bottom: 60px; /* Same as the height of internship-certificate to avoid overlap */
}

.certificate-module {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 10px;
  box-shadow: 0 -2px 10px rgba(0,0,0,0.5);
  background-color: #26303E;
  height: 82px;
  font-family: 'OpenSansextrabold';
  color: #ffffff;
  font-size: 18px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  /*margin-bottom: -10px;*/
}
.internship-certificate{
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  cursor: pointer;
}

.internship-certificate:hover{
  background-color: #444444;
  transition: 0.8s ease;

}

#certificate-id{
  border-bottom: 2px solid #1D2531;
}
.certificate-icon{
  height: 30px;
  margin-top: 5px;
  padding-right: 20px;
}

.sidebar-container {
  flex: 1;
  overflow-y: auto;
  max-height: calc(100vh - 60px);
  margin-top: 0px;
  background-color: #1D2531;
  padding: 0px;
  padding-bottom: 100px;
  padding-top: 0.1rem;
}

/* Custom scrollbar styles for WebKit browsers (Chrome, Safari) */
.sidebar-container::-webkit-scrollbar {
  width: 5px; /* Width of the entire scrollbar */
}

.sidebar-container::-webkit-scrollbar-track {
  background: transparent; /* Hide the scrollbar track */
}

.sidebar-container::-webkit-scrollbar-thumb {
  background-color: #474747; /* Color of the scrollbar thumb */
  border-radius: 50px; /* Round the corners of the thumb */
  border: 2px solid #1D2531; /* Match the background color to hide the thumb border */
}

/* Remove the scrollbar arrows for WebKit browsers (Chrome, Safari) */
.sidebar-container::-webkit-scrollbar-button {
  display: none; /* Hide the arrow buttons */
  color: #1D2531;
}

/* Custom scrollbar styles for Firefox */
.sidebar-container {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #474747 transparent; /* Thumb color and transparent track color */
}

/* Add scrollbar styling */
.sidebar::-webkit-scrollbar {
  width: 0px;
  background-color: #1D2531;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #888;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}


.sidebar-toggle-icon {
  display: none;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
  margin: 2px;
}
  
  .module-list {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-right: 0px;
    padding: 0px;
    padding-bottom: 80px;
}
  
  .module {
    padding: 15px;
    transition: background-color 0.3s;
  }

  .module-name {
    font-size: 18px;
    color: #FFFFFF;
    font-family: 'OpenSansextrabold';
  }
  
  .module:hover {
    background-color: #1D2531;
    
  }
  
  .module.active {
    background-color: #1D2531;
  }

  .lesson-content {
    margin-top: 65px;
    padding: 20px; /* Add padding to lesson content */
    height: calc(100vh - 100px); /* Set a fixed height for the lesson content */
    overflow-y: auto; /* Enable vertical scrolling */
    position: relative;
    margin-left: 10px;
    padding-bottom: 80px;
    padding-bottom: 80px;
  }

  .lesson-content {
    overflow-y: auto; /* Ensure the content can scroll vertically */
    scrollbar-width:thin; /* For Firefox */
    scrollbar-color: #cacaca #FFFFFF; /* For Firefox */
}

/* For Webkit browsers (Chrome, Safari) */
.lesson-content::-webkit-scrollbar {
    width: 8px; /* Make the scrollbar thinner */
}

.lesson-content::-webkit-scrollbar-track {
    background: white; /* Background of the scrollbar track */
    border-radius: 20px; /* Make the track rounded */

}

.lesson-content::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Make the thumb rounded */
    border:none;
}

.lesson-content::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb on hover */
}

  .lesson-list {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .lesson {
    padding: 2px 1px;
    padding-right: 0px;
    cursor: pointer;
    color: #C5C4C4;
    font-family: 'OpenSans';
    font-size: 14px;
  }
  
  .lesson.active {
    background-color: #474747;
    color: #FFFFFF;
  }

  .lesson.activecompleted {
    background-color: #474747;
    color: #FFFFFF;
  }


  .topic-info {
    display: flex;
    align-items: center;
    margin: 30px;
  }
  .topic-name-in-header-1{
    display: none;
  }
  
  .topic-icon {
    width: 15px; 
    height: 15px;
    margin-right: 10px;
  }
  
  .topic_name {
    font-size: 24px;
    font-weight: bold;
    margin-left: -30px;
    font-family: 'OpenSansextrabold';

  }
  
  .lesson-screen strong {
    color: #000000;
    font-family: 'OpenSansextrabold';
    font-size: 16px;
  }

  .lesson-screen {
    color: #444444;
    font-family: 'OpenSans';
    font-size: 15px;
  }

  .lesson-screen iframe{
    align-self: center;
    margin-left: 15%;
  }
  .main-content {
    flex: 3;
    background-color: #fff;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    height:fit-content; /* Set a fixed height for the main content */
  }


  .main-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px; /* Adjust padding for spacing */
    background-color: #F1F5F7;
    align-items: center;
    position: fixed;
    top: 0;
    width: 75%;
    z-index: 2; /* Ensure main header is above sidebar header */
    /* margin-left: -5%; */
  }

  .course-header{
    display: flex;
    justify-content:space-between;
    align-items: center;
    width: 100vw;
    margin-left: -36%;
    background-color: #F1F5F7;
    padding: 0 35px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    z-index: 1;
    padding-left: 60px;
    padding-right: 60px;
    border-bottom: 2px solid #A8A8A8;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08);
    }

  .back-icon {
    flex: 0 0 auto;
    margin-right: auto;
    cursor: pointer;
  }

 
  .training-name{
    font-family: 'OpenSansextrabold';
    font-size: 18px;
    flex: 1;
    text-align: center;
    word-wrap: break-word;
  }

  .progress-bar-component {
    flex: 0 0 auto;
    margin-left: auto;
    height:60px;
    }

.sidebar-header,
.main-header {
  border: none;
  height: 40px;
}
.web-prev-next-button-container{
  display: flex;
  bottom: 0; /* Place it at the bottom */
  left: 0; /* Align it to the left edge */
  right: 0; /* Align it to the right edge */
  justify-content: flex-end;
  position: fixed;
  padding-right:20px;
  box-shadow: 0 -8px 10px -6px rgba(0, 0, 0, 0.1);
  background-color: white; /* Ensure it has a background color */
  padding-top: 15px;
  padding-bottom: 15px;
  z-index: 100; 
}

.previous-icon,
.next-icon{
  height: 18px;
  padding-right: 7px;
  padding-left: 7px;
  margin-bottom: -2px;
}
  .prev-next-button-web {
    padding: 7px 12px;
    border: none;
    border-radius: 10px;
    background-color: #9BDF46;
    color: #FFFFFF;
    cursor: pointer;
    font-family: 'OpenSans';
    font-weight: 500;
    font-size: large;
    margin-left: 20px;
    align-items: center;
  }
  
  .prev-next-button:hover {
    background-color: #7ed321;
    color: #fff;
  }

  .arrow-button {
    display: none;
  }

  .arrow {
    width: 10px;
    height: 10px;
    display: flex;
  }
  
  .arrow.left {
    border: solid #7ed321;
    border-width: 0 4px 4px 0;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    margin-right: 0;
  } 

  .arrow.right {
    border: solid #7ed321;
    border-width: 0 4px 4px 0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-left: 0;
  }

  .arrow.left:hover{
    border: solid #fff;
    border-width: 0 4px 4px 0;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    margin-right: 0;
  }

  .arrow.right:hover{
    border: solid #fff;
    border-width: 0 4px 4px 0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-left: 0;
  }

  /* PHONE VIEW UPDATES - STANDARD FONT, IMAGE, VIDEO SIZE. */
  .lesson-content.phone-view {
    font-size: 15px; /* Standard font size for phone view */
    white-space: normal; /* Allow text to wrap to the next line */
    text-wrap: wrap;
  }

  .lesson-content.phone-view img {
    max-width: 95%; /* Ensure images don't overflow their container */
    height: auto; 
  }
  .lesson-content.phone-view video {
    max-width: 95% !important;
    height: auto !important;
    margin: 0 !important;
    display: block !important;
  }


  /* PHONE VIEW - 1 */
  /* Show arrow buttons only for phone/tablet view */
  @media (max-width: 470px) {
    .prev-next-button {
      padding: 10px;
    }
  
    /* Hide original Next and Previous buttons for phone/tab view */
    .prev-next-button.web {
      display: none;
    }

    .arrow-button {
      display: inline-block;
      padding: 10px;
      border-radius: 50%;
    }

    .sidebar {
      flex: 3000;
      position: relative;
      background-color: #ffffff;
      padding: 0;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      overflow-y: auto;
      max-height: 150vh;
      transform: translateX(0);
      transition: transform 0.5s ease;
      z-index: 5;
      display: block;
      border: none;
    }
  
    .sidebar-content.visible {
      display: block;
      opacity: 1;
      transition: opacity 0.3s ease; /* <-- Content animation */
      margin: 0px;
      padding: 0px;
    }
  
    .sidebar-content {
      flex: 1;
      overflow-y: auto;
      max-height: calc(100vh - 60px);
      margin-top: 10px;
      display: none;
      opacity: 0;
    }

    .sidebar-header {
      position: fixed;
      top: 0;
      width: 100%;
      background-color: #2b3636;
      padding: 8px;
      display: flex;
      justify-content: space-between; /* Adjusted property */
      align-items: center; /* To vertically center the contents */
      z-index: 1;
      flex-direction: row;
      margin-top: 13.5%;
    }
  
    .sidebar-toggle-icon {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      align-items: start;
      padding-right: 10px;
    }

    .dot {
      width: 25px;
      height: 2px;
      background-color: white;
      margin: 2.4px;
      transition: background-color 0.3s;
    }
  
    .dot.active {
      background-color: white;
      margin-left: 13px;
    }

    .lesson-content{
      overflow-x: auto;
    }

    .main-content {
      flex: 3;
      padding: 0;
      background-color: #fff;
      /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
      overflow-y: hidden;
      overflow-x: hidden;
      transition: margin-left 0.3s ease; /* <-- Main content animation */
    }
    .module-list{
      margin-top: -3px;
    }
  
    .main-content.visible {
      margin-left: 0;
    }

    .sidebar-hidden .sidebar {
      transform: translateX(-110%);
    }
  
    .sidebar-hidden .main-content {
      padding-right: 420px;
    }

    .sidebar-hidden .main-header {
      padding-right: 40px;
    }
    
    .sidebar-hidden .lesson-content {
      width: 95vw; /*vw is viewport width that sets as per the width of the screen. This sets to 95% of the width */
    }

    .sidebar-container {
      margin-top: 100px;
    }

    .main-header{
      width: 100%;
    }
    .course-header {
      padding: 8px 15px; /* Adjust padding for smaller screens */
      border-bottom: 2px solid #A8A8A8;
      position: fixed;
      margin-left:-30px;
  }
  
  .back-icon {
      margin-right: 6px; /* Adjust margin for smaller screens */
  }

  .training-name {
      font-size: 13px; /* Adjust font size for smaller screens */
  }

  .progress-bar-component {
      height:20px; /* Adjust height for smaller screens */
      margin-top: -20px;
  }

  #course-header-sidebar-visible{
    margin: 0px;
    padding: 9px 15px; 
    margin-left: -10px;
    position: fixed;
  }

  #sidebar-header-visible{
    background-color: #1D2531;
  }
  #main-header-for-toggle{
    margin-top: 13%;
    background-color: #1D2531;
    text-align: center;
  }
 
  #course-header-for-toggle{
    margin-top: -27%;
  }

  .topic-name-in-header-1{
    text-align: left;
    color: #FFFFFF;
    font-family: 'OpenSansextrabold';
    font-size: 16px;
    margin-left: 0px;
    width: 100%;
  }
 .topic-info{
  color: white;
 }

  .web-prev-next-button-container{
    display: flex;
    margin-left: -5%;
    width: 100%;
    margin-top: 5%;
    justify-content:flex-end;
    padding-right:0px;
    /* box-shadow: 0 -8px 10px rgba(0, 0, 0, 0.1); */
    padding-top: 15px;
    padding-bottom: 15px;
    z-index: 1;
   }

   .previous-icon,
.next-icon{
width: 18px;
}
  .prev-next-button-web{
    font-size: 12px;
    border-radius: 6px;
    padding: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .topic-name-in-header-1{
    display:block;
  }

  .lesson-screen{
    padding-right: 20px;
  }
  .certificate-module{
    width: 100%;
  }
  }

    /* PHONE VIEW - 2 */
    /* Styles for this range of width between 420px and 768px*/
    @media (max-width: 768px) and (min-width: 470px) {
      .prev-next-button {
        padding: 10px;
      }
    
      /* Hide original Next and Previous buttons for phone/tab view */
      .prev-next-button.web {
        display: none;
      }
  
      .arrow-button {
        display: inline-block;
        padding: 10px;
        border-radius: 50%;
      }
  
      .sidebar {
        flex: 3000;
        background-color: #fff;
        padding: 0;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        overflow-y: auto;
        max-height: 150vh;
        transform: translateX(0);
        transition: transform 0.5s ease;
        z-index: 5;
        display: block;
      }
    
      .sidebar-content.visible {
        display: block;
        opacity: 1;
        transition: opacity 0.3s ease; /* <-- Content animation */
      }
    
      .sidebar-content {
        flex: 1;
        overflow-y: auto;
        max-height: calc(100vh - 60px);
        margin-top: 10px;
        display: none;
        opacity: 0;
      }
  
      .sidebar-header {
        position: fixed;
        top: 0;
        width: 100%;
        background-color: #1D2531;
        padding: 8px;
        display: flex;
        justify-content: space-between; /* Adjusted property */
        align-items: center; /* To vertically center the contents */
        z-index: 1;
        flex-direction: row;
        margin-top: 13.5%;
      }
    
      .sidebar-toggle-icon {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        align-items: start;
      }
  
      .dot {
        width: 25px;
        height: 2px;
        background-color: white;
        margin: 2.4px;
        transition: background-color 0.3s;
      }
    
      .dot.active {
        background-color: white;
      }
  
      .lesson-content{
        overflow-x: auto;
      }
  
      .main-content {
        flex: 3;
        padding: 0;
        background-color: #fff;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        overflow-y: hidden;
        overflow-x: hidden;
        transition: margin-left 0.3s ease; /* <-- Main content animation */
      }
    
      .main-content.visible {
        margin-left: 0;
      }
  
      .sidebar-hidden .sidebar {
        transform: translateX(-110%);
      }
    
      .sidebar-hidden .main-content {
        padding-right: 786px;
      }
  
      .sidebar-hidden .main-header {
        padding-right: 70px;
      }
      
      .sidebar-hidden .lesson-content {
        width: 95vw; /*vw is viewport width that sets as per the width of the screen. This sets to 95% of the width */
      }

      .sidebar-container {
        margin-top: 100px;
      }

      .main-header{
        width: 100%;
      }
      .course-header {
        padding: 8px 15px; /* Adjust padding for smaller screens */
        border-bottom: 2px solid #A8A8A8;
        position: fixed;
        margin-left:-30px;
    }
    
    .back-icon {
        margin-right: 6px; /* Adjust margin for smaller screens */
    }
  
    .training-name {
        font-size: 13px; /* Adjust font size for smaller screens */
    }
  
    .progress-bar-component {
        height:20px; /* Adjust height for smaller screens */
        margin-top: -20px;
    }
  
    #course-header-sidebar-visible{
      margin: 0px;
      padding: 9px 15px; 
      margin-left: -10px;
      position: fixed;
      margin-top: -2%;
    }
  
    #sidebar-header-visible{
      background-color: #1D2531;
    }
    #main-header-for-toggle{
      margin-top: 13%;
      background-color: #1D2531;
    }
   
    #course-header-for-toggle{
      margin-top: -26%;
    }

    .module-list{
      margin-top: -10px;
    }

    .topic-name-in-header-1{
      text-align: left;
      color: #FFFFFF;
      font-family: 'OpenSansextrabold';
      font-size: 16px;
      width: 100%;
    }

    .web-prev-next-button-container{
      display: flex;
      margin-left: -5%;
      margin-top: 5%;
      width: 100%;
      justify-content: right;
      padding-right:30px;
      box-shadow: 0 -8px 10px rgba(0, 0, 0, 0.1);
      padding-top: 15px;
      padding-bottom: 15px;
      z-index: 1;
    }
  
    .previous-icon,
    .next-icon{
      width: 18px;
    }

    .prev-next-button-web{
      font-size: 12px;
      border-radius: 6px;
      padding: 10px;
      padding-top: 7px;
      padding-bottom: 7px;
    }

    .topic-name-in-header-1{
      display:block;
    }

    .topic-info{
      color: white;
     }

    .certificate-module{
      width: 100%;
    }
    }



