/* GenerateKey.css */

.generate-key-container {
    width: 100%; /* Set width to 100% for responsiveness */
    max-width: 500px; /* Limit maximum width */
    margin: 20px auto;
    margin-top: 40px;
    padding: 20px;
    border: 1px solid lawngreen;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display: flex; /* Set display to flex */
    flex-direction: column; /* Set flex direction to column for vertical layout */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}

.generate-key-container h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
}

.radio-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.enable-radio-button {
    display: flex;
    align-items: center;
}

.enable-radio-button input[type="radio"] {
    margin-right: 5px;
    /* Customize radio button appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid #9bdf46; /* Default border color */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    outline: none;
    transition: border-color 0.3s ease;
    position: relative;
}

.enable-radio-button input[type="radio"]:checked::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #9bdf46;
    transition: background-color 0.3s ease;
    position: absolute;
    top: 0;
    left: 0;
}

.enable-radio-button input[type="radio"]:checked::after {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    border-radius: 50%;
    background-color: #fff;
    transition: background-color 0.3s ease;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.api-key-field {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.api-key-text {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%; /* Set width to 100% */
    max-width: 100%;
}

.copy-api-button {
    margin-left: 10px;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background-color: white;
}

.copy-api-button{
   margin-left: -5px;
}

.copy-api-button:hover {
    background-color: white;
}

button,
input[type="radio"] {
    font-size: 1rem;
}

button {
    background-color: #9bdf46;
    color: #fff;
}

button:hover {
    background-color: #7fb938;
}



.generate-key-button  {
    height: 35px;
    width: 30%;
    margin-left: 18px;
    border: none;
    border-radius: 20px;
    font-size: 20px;
    background-color: #9bdf46;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease, opacity 0.3s ease;}

.generate-key-button:hover {
    background-color: #7fb938;
}


/* popup css */
.api-key-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.api-key-popup {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 600px; /* Adjust the width as needed */
    height: 280px; /* Adjust the height as needed */
    max-width: 80%;
    text-align: center;
}

.api-key-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.api-warning-text{
 font-size: 16px;
 font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
 text-align: left;
}

.api-text-and-copy{
display: flex;
width: 98%;
}

.api-key-textbox {
    border: 1px solid #ccc;
    border-radius: 6px;
    margin: 10px 0;
    padding: 10px;
    font-size: 16px;
    word-wrap: break-word;
    flex: 1;
    margin-top: 20px;
    max-width: 80%; /* Set maximum width to 100% */
    overflow-wrap: break-word;
}

.copy-api-button {
    background-color: #ffffff;
    color: #fff;
    border: none;
    padding: 0px;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
}

.copy-api-button:hover {
    background-color: #dddddd;
}

.copy-api-button img {
    height: 40px;
    padding: 0;
    margin: 0;
}

.cancel-button {
    background-color: #9bdf46;
    margin-top: 15px;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 30px;
    cursor: pointer;
    height: 50px;
    width: 120px;
    font-size: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    transition: background-color 0.3s ease, opacity 0.3s ease;
}

.cancel-button:hover {
    background-color: #7fb83a;; /* Darker red color on hover */
    
}


@media (max-width: 768px) {
    .generate-key-container{
        font-size: 14px;
        margin-left: 12px;
        width: 87%;
        margin-right: 0px;
        padding-right: 0px;
    }
    
    .generate-key-button{
        height: fit-content;
        font-size: 13px;
        width:25%;
        border-radius: 15px;
        margin-right: 7px;
    }
    
    .api-key-popup{
        height:fit-content;
    }

    }
