.api-usage-content-container{
    display: flex;
    flex-direction: column; 
    min-height: calc(100vh - 75px);
    margin-bottom: 20px; 
}

@media screen and (max-width: 600px) {
    .api-usage-content-container {
        padding: 10px; /* Adjust padding for smaller screens */
    }

    .api-heading h1 {
        font-size: 1.2rem; /* Reduce font size for smaller screens */
    }

}