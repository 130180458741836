  
.page-header {
  display: flex;
  justify-content: space-between;
  background: #FFFFFF;
  /* background: #2B3636; */
  position: fixed;
  align-items: center;
  margin-bottom: 20px;
  top: 0;
  width: 100%;
  height: 40px;
  padding: 10px 20px 10px 20px;
  z-index: 1000; 
  border-bottom: 1px solid #DEDEDE;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08);
}

.left-content {
  flex: 1;
  display: flex;
  align-items: center;
}

.right-content {
  display: flex;
  align-items: center;
  gap: 20px;
}

.logo {
  width: 150px;
  height:50px;
  margin-top: 5px;
  margin-left: 25px;

}

.greeting-msg{
  margin-left: -7.6rem;
  margin-right: 1rem;
  margin-top: 12px;
  color: rgb(18, 19, 63);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px;
}

.profile-and-name {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 80px;
}

.right-content a {
  color: #bac1c7;
  text-decoration: none;
  margin-right: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.right-content a:hover {
  color: #ffffff;
}


.header-button {
padding: 8px 16px;
cursor: pointer;
}

.profile-dropdown {
position: relative;
justify-content: right;
}

.profile-icon {
  position: relative;
  width: 40px;
  height: 40px;
  top:1.67px;
  margin-right:10px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
}

.profile-icon:hover {
  color: rgb(104, 100, 100);
}

.dropdown-menu {
  position: absolute;
  top: 140%; /* Position below the profile icon */
  left: 80%; /* Align horizontally with the profile icon */
  transform: translateX(-50%); /* Center the dropdown relative to the icon */
  margin-right: 50%;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  padding: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  z-index: 100;
  animation: fadeIn 0.3s ease;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  font-size: 14px;
  color: #333;
  padding: 12px 0;
  cursor: pointer;
  transition: background-color 0.3s; 
  border-bottom: 1px solid #ddd;
}

/* Remove the border-bottom from the last option to avoid an extra line */
.dropdown-menu li:last-child {
  border-bottom: none;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

  
  .dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdown-menu li {
    font-size: 14px;
    color: #333;
    padding: 12px 0;
    cursor: pointer;
    transition: background-color 0.3s; 
    border-bottom: 1px solid #ddd;
  }
  
  .dropdown-menu li:last-child {
    border-bottom: none;
  }
  
  .dropdown-menu li:hover {
    background-color: #f0f0f0;
  }

  @media (max-width: 767px ) {

    .page-header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1000;
      box-sizing: border-box;
      padding-right: 30px;
      
    }
    .right-content {
        padding-right: 31px;
    }

    .logo {
      margin-left: 5%;
      /* display:none; */
    }

    .profile-icon{
      margin-right: 50px;
      
    }
    
    .greeting-msg{
      margin-left: 10rem;
      margin-bottom: 2px;
      font-size: 12px;
    }
}
  

@media (max-width: 767px ) {

    .page-header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1000;
      height: 77px;
      box-sizing: border-box;
      
    }

    .right-content {
        margin-right: -75px;
    }


    .logo {
      height: 50px;
      width: 120px;
      margin-top: 20px;
      margin-left: -8px;
      }

      img.profile-icon{
        height:28px;
        margin-top: 10px;
        margin-bottom: -10px;
        margin-right: 5px;
      }
    .greeting-msg{
      margin-left: 20%;
      margin-top: 1rem;
      margin-bottom: 2px;
      margin-right: 0px;
      font-size: 12px;
    }
    .profile-and-name{
      margin-right: 30px;
    }
}
  
@media (max-width: 400px ) {
  .greeting-msg{
    margin-left: 0rem;
    margin-right: 7px;
    margin-top: 1rem;
    margin-bottom: 0px;
    font-size: 12px;
  }

  /* .logo{
    display:none;
  } */
}

@media (min-width: 400px ) {
  .greeting-msg{
    margin-left: 0rem;
    margin-top: 1rem;
    margin-right: 7px;
    margin-bottom: 0px;
    font-size: 16px;
  }

  /* .logo{
    display:none;
  } */
}
