/* Header.css */

.header {
  width: auto;
  height: 800px;
  position: relative;
  background-image: url("./images/main.jpg"); /* Path to your background image */
  background-size: cover; /* Adjust the background size as needed */
  background-position: center center; /* Adjust the background position as needed */
  background-repeat: no-repeat; /* Prevent background image from repeating */
  padding: 1%;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Adjust the height to cover the entire header */
  background-color: rgba(0, 0, 0, 0.6); /* Adjust the opacity (0.5 for 50%) */
}

.container {
  position: static;
}

.logo {
  position: absolute;
  width: 180px; /* Adjust the width of the logo as needed */
  top: 0px;
}

.login-button {
  padding: 13px 25px;
  border: none;
  border-radius: 35px;
  background-color: rgb(155, 223, 70); /* Change the button color as needed */
  color: #fff;
  cursor: pointer;
  font-size: medium;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  position: relative;
}

.button-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-text {
  font-size: 20px; /* Adjust the size of the main text */
  font-weight: 700;
  margin-bottom: 5px;
}

.sub-text {
  font-size: 13px; /* Adjust the size of the sub text */
}

/* Optional: Add hover styles for the buttons */
.login-button:hover,
.enroll-button:hover,
.view-all-products:hover {
  opacity: 0.8;
}

.center-content {
  position: relative;
  text-align: center;
  margin-bottom: 15%;
  margin-top: 15%; /* Adjust the margin-top to center the content */
  color: #fff;
}

h2{
  font-weight: 700;
  font-size: 55px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 30px;
}

.chat_with_us {
  background-color : white;
  color: black;
  padding: 5px 10px;
  border-radius: 10px;
  border: none;
  font-size:18px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-weight: bold;
  }

  .whatsapp_button {

  width: fit-content;
  z-index:999;
  position: fixed;
  bottom: 15%;
  right: 10px;

  }

  .whatsapp_image{
    width:50px;
    position:relative;
    top:5px;
  }

.footer {
  position: fixed;
  background-color: #2b3636;
  padding: 30px 30px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.footer-left {
  color: #bac1c7;
  flex: 1;
}

.footer-center {
  flex: 2;
  display: block;
}

.footer-right {
  flex: 1;
}

.footer a {
  color: #bac1c7;
  text-decoration: none;
  margin-right: 20px;
}

.footer a:hover {
  color: #ffffff;
}


@media (max-width: 480px) {

  /* Adjust styles for phone view */
  .login-button,
  .signup-button {
  padding: 12px 12px;
}

  .center-content {
    padding: 45% 0;
    margin: 0;
    text-align: center;
    color:#ffffff;
  }

  .enroll-button,
  .view-all-products {
    padding: 8px 15px;
    font-size: 14px;
  }

  .featured-course {
    font-size: 24px;
  }

  .card-image {
    height: 150px;
  }

  /* Footer */
  
  .footer {
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
  }

  .footer-left,
  .footer-center,
  .footer-right {
    margin-bottom: 10px;
  }

  .footer a {
    display: block;
    margin: 5px 0;
    font-size: 12px;
  }
}