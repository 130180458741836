#root {
    height: 100vh;
    margin: 0;
    padding: 0;
  }
  .lesson-plan-generator-container {
    width: 100%;
    height: 100vmax;
    margin: 0;
    padding: 0;
  }

  @media (max-width: 767px) {
    .lesson-plan-generator-container{
    width:fit-content;
    height: 100vh;
    margin: 0;
    }
  }

