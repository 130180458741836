/* AssuredInternshipsPage.css */

.assured-internships-page {
    padding-bottom: 0px;
    width: 100%;
    margin: 0;
    margin-top: 80px;
  }

  .internship-heading {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 2em;
    margin: 10px;
  }
  
  .internship-info {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 16px;
    margin: 20px;
  }

  .internship-rules{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 16px;
    margin: 20px;
  }
  
  .accordion {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    width: 95%;
    border: 1px solid #ddd;
    margin: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .accordion:hover {
    background-color: #f9f9f9;
  }
  
  .accordion.expanded .accordion-header {
    background-color: #939590; /* Green background when expanded */
    color: white;
  }
  
  .accordion-header {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: #f1f1f1;
    font-weight: bold;
  }
  
  .accordion-content {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding: 15px;
    display: none;
  }

  .accordion-content li {
    margin-bottom: 10px; /* Adjust the value to set the desired space */
  }
  
  .accordion.expanded .accordion-content {
    display: block;
  }

  .form-text{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: large;
    margin:25px;
  }
  
  .form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 25px; 
    position: relative; 
  }
  .glassy-overlay {
    position: absolute; /* Position the overlay relative to the form container */
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2; /* Ensure the overlay is above other content */
    margin-bottom: 35px;
  }
  .overlay-text {
    font-size: 24px;
    color: white;
    text-align: center;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background for the text container */
    border-radius: 10px; /* Rounded corners */
    max-width: 90%; /* Limit the width of the overlay text */
    overflow-wrap: break-word; /* Allow text to wrap if it exceeds the width */
  }


