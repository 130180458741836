

.faculty-dashboard-container {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    min-height: 100vh;
    background-color: rgb(255, 255, 255);
    overflow-x: hidden;
  }
  
  .content-container {
    flex: 1;
    padding: 0px; /* Adjust padding as needed */
    overflow-y: auto;
    margin-left: 17%; 
    margin-top: 90px;
    justify-content: center;
    margin-bottom: 50px;
  }

  .message-container {
    padding: 10px;
    background-color: #95eaa3; /* Example background color for error messages */
    color: #000000; /* Example text color for error messages */
    border: 1px solid #000000; /* Example border color for error messages */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    margin: 10px;
    margin-top: 7%;
    margin-left: 50px;
    margin-right: 50px;
    border-radius: 5px;
    display: block; /* Ensure the message container is displayed */
    text-align: center;
    /* Add any other styles as needed */
  }

  @media (max-width: 767px) {

    .faculty-dashboard-container {
      display: flex;
      flex-direction: column; /* Stack children vertically */
      min-height: 100vh;
      background-color: rgb(255, 255, 255);
      overflow-x: hidden;
    }

    .content-container {
      margin-left: 10px; 
      margin-right: 10px; 
      margin-top: 85px;
    }
      
    .message-container{
      padding: 10px;
      background-color: #95eaa3;
      color: #000000;
      border: 1px solid #000000;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-size: 16px; /* Adjust font size for mobile */
      margin-top: 23%; /* Reduced top margin for mobile */
      margin-left: 28px; /* Adjusted left margin for mobile */
      margin-right: 28px; /* Adjusted right margin for mobile */
      border-radius: 5px;
      display: block;
      text-align: center;
      width: 80%;
    }
    
  }