/* InternshipsStipendPage.css */

html,
body,
#root {
  height: 100%;
  margin: 0;
  
}

.internships-stipend-page {
  height: 100%;
  width: 100%;
  padding-bottom: 0px;
  margin-top: 60px;
}

.home-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin: 10px;
}

/* Add these styles for the loading spinner */
.loading-spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the loading spinner appears on top of other elements */
}

.loading-spinner {
  border: 8px solid #f3f3f3; /* Light gray border */
  border-top: 8px solid rgb(155, 223, 70); /* Blue border for animation */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Spin animation */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .home-icon {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin: 10px;
  }

}


