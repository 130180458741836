.footer {
    background-color: #2b3636;
    color: #fff;
    text-align: center;
    padding: 10px;
    padding-right: 40px;
    width: 100%;
    box-sizing: border-box; /* Ensure padding is included in the width calculation */
    position: relative;
    height: 50px;
    font-size: 18px;
    display: flex; /* Use flexbox for layout */
    justify-content: space-between; /* Distribute space evenly */
    align-items: center; /* Center items vertically */
}

.footer-item {
    flex: 1; /* Allow each section to grow equally */
    display: flex;
    justify-content: center;
    padding: 0px; /* Add padding to each item */
}

.footer-left {
    justify-content: flex-start; /* Align left content to the start */
    padding-left: 80px; /* Add left padding to "Bolt IoT" */
    
}


.footer-center {
    justify-content: center; /* Center the middle content */
    margin-left: 6%;
}

.footer-right {
    justify-content: flex-end; /* Align right content to the end */
    padding-right: 10px; /* Add right padding to "Privacy Policy" */
    padding-right: 80px;
}

.footer a {
    color: #bac1c7;
    text-decoration: none;
    margin-right: 20px;
}

.footer a:last-child {
    margin-right: 0; /* Remove margin from the last link */
}

.footer a:hover {
    color: #ffffff;
}

@media (max-width: 420px) {
    .footer {
        flex-direction: row; /* Ensure items stay in a row on smaller screens */
        font-size: 12px;
    }
    .footer-item {
        flex: 1; /* Ensure each section takes equal space */
        align-items: center;
        font-size: 12px;
       }
    .footer-left,.footer-center,.footer-right{
        margin: 0;
        padding: 0;
    }
    .footer-left{
        padding-left: 20px;
    }
}

@media (max-width: 768px) and (min-width: 420px) 
{
    .footer {
        flex-direction: row; /* Ensure items stay in a row on smaller screens */
        font-size: 12px;
    }
    .footer-item {
        flex: 1; /* Ensure each section takes equal space */
        align-items: center;
        font-size: 12px;
       }
    .footer-left,.footer-center,.footer-right{
        margin: 0;
        padding: 0;
    }
    .footer-left{
        padding-left: 20px;
    }
}
