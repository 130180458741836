.course-timeline {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto; /* Centers the element horizontally */
  padding: 5%;
  padding-bottom: 0px;
  margin-top: 50px;
  position: relative;
  height: 60px; 
  width: fit-content;
}

.course-timeline-item {
  display: flex;
}

.box-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  padding-left: 30px; 
  padding-right: 30px; 
  margin: 0;
}

.circle-arrow {
  display: flex;
}

.timeline-circle {
  width: 35px; 
  height: 35px; 
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 2px solid #B7B7B7;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
}

.timeline-content {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #404040;
}

.timeline-arrow {
  margin-top: -7px;
  width: 100%;
  height: 2px;
  background-color: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.trophy-icon-img {
  height: 50px;
}

.right_arrow {
  height: 40px;
  margin-top: -5px;
  z-index: 1;
}

#line-1 {
  background-color: #B7B7B7;
  margin-top: -15px;
  margin-left: 163px;
  width: 100px;
  border-radius: 10%;
}

#line-2 {
  background-color: #B7B7B7;
  margin-top: -15px;
  margin-left: 176px;
  width: 119px;
  border-radius: 10%;
}

#line-3 {
  background-color: #B7B7B7;
  margin-top: -15px;
  margin-left: 182px;
  width: 125px;
  border-radius: 10%;
}

#arrow-1 {
  margin-left: 100px;
  margin-top: -6px;
}

#arrow-2 {
  margin-left: 115px;
  margin-top: -6px;
}

#arrow-3 {
  margin-left: 115px;
  margin-top: -6px;
}

#fourth-circle{
  border: none;
  margin-top: -55px;
  margin-bottom: 24px;
}
#fourth-title{
  margin-top: -5px;
}

.prize-icon{
  height:60px;
  margin-top: -12px;
}
@media (max-width: 768px) {
  .course-timeline {
    padding: 20px;
    width: 80%;
    margin: 40px auto 0 auto;
    margin-top: 100px;
  }

  .box-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .timeline-arrow {
    margin-left: 0;
    width: calc(100% - 40px);
  }

  .timeline-circle {
    height: 20px;
    width: 20px;
    font-size: 14px;
  }
  #line-1 {
    margin-left: 80px;
    margin-top: -20px;
    width: calc(100% - 27px);
  }

  #line-2 {
    margin-left: 90px;
    margin-top: -20px;
    width: calc(100% - 45px);
  }
  #line-3 {
    margin-left: 90px;
    margin-top: -20px;
    width: calc(100% - 40px);
  }

  #stage-four {
    margin-top: -23px;
  }

  .timeline-content {
    font-size: 12px;
  }

  #fourth-circle{
    margin-top: -43px;
    margin-bottom: 24px;
  }
  #fourth-title{
    margin-top: 0px;
  }
  
  .prize-icon{
    height:50px;
    margin-top: 6px;
  }
}

@media (max-width: 390px) {
  #arrow-1 {
    margin-left: 18px;
  }

  #arrow-2 {
    margin-left: 31px;
  }
  #arrow-3 {
    margin-left: 28px;
  }
}
