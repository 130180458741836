/* ProductsPage.css */

/* fonts */
@font-face {
  font-family: 'OpenSansextrabold';
  src: url('./fonts/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PoppinsRegular';
  src: url('./fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.products-page {
  background-color: #fff;
  padding-bottom: 0px;     /*To fix the footer getting overlapped on content */
  overflow: hidden;
  margin-top: 60px;
  margin: 0px;
}

/* Training cards */
.card-container {
display: flex;
flex-wrap: wrap;
justify-content: center;
margin: 40px;
gap: 25px;
padding: 50px;
padding-top: 25px;
padding-bottom: 30px;
}


.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 calc(27% - 2px);
  background-color: #1D2531;
  padding: 32px;
  height: 390px;
  border-radius: 18px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  transition: border-color 0.3s;
  margin-bottom: 3px;
  box-sizing: border-box;
}

.card-content {
  flex-grow: 1;
}

/* Add hover effect styles */
.card:hover {
  border: 1.5px solid rgb(155, 223, 70); 
}

/* Change the heading color when hovering over the card */
.card:hover .card-heading {
  color: rgb(155, 223, 70);
}


.card-image-container {
 position: relative;
 background-color: #323E50;
 border-radius: 8px; 
}

.card-image {
  width: 240px;
  height: 200px;
  object-fit: fill; 
  border-radius: 10px; 
  margin-bottom: 0px;
}


.card-heading {
  font-size: 16px;
  font-family: 'OpenSansextrabold';
  color: #FFFFFF;
  transition: color 0.3s;
  font-weight:normal;
  font-style:normal;
  height: 50px;
}

.card-description {
  font-size: 16px;
  color: #777; 
}

.card-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.start-training-button {
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.start-training{
  font-size: 13px;
  font-weight: bold;
  font-family: 'OpenSans';
  margin-left: 18px;
  border-radius: 10px;
  border: none;
  background-color: #9BDF46;
  padding: 8px;
  color: #FFF9F9;
  cursor: pointer;
}

#start-training-button{
  margin-bottom: -20%;
}
.card-footer{
  justify-content: center;
}

/* css for no trainings message */
.no-trainings-message {
  margin-top: -30px;
  height:fit-content;
  width: 550px;
  /* margin-left: 347px; */
  margin-left: 0px;
  background-color: #323E50;
  border-radius: 12px;
  padding: 21px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

#oops-title{
  font-size: 60px;
  margin-top: 20px;
  font-family: 'OpenSans';
  margin-bottom: 10px;
  color: white;
}

#para-1{
  color: white;
  font-size: 18px;
  font-family: 'PoppinsRegular';
  margin-top: 2px;
  word-wrap: break-word;
  white-space: normal;
  width: 62%;
}

#para-2{
  color:#C7C7C7;
  font-size: 14px;
  font-family:'OpenSans';
  margin-bottom: -4px;
}
#para-3{
  color:#C7C7C7;
  font-size: 14px;
  font-family:'OpenSans';
  margin-bottom: -4px;
}
#user_email_in_msg{
  color: white;
  font: 'OpenSansextrabold';
  font-weight: bold;
}
#para-4{
  color:white;
  font-size: 14px;
  font-family:'OpenSans';
  font-weight: 600;
  margin-bottom: 20px;
}
.whatsapp-button {
  background-color: #9BDF46;
  color: #FFFFFF;
  border: none;
  font-size: 14px;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: fit-content;
}

.whatsapp-button:hover {
  background-color: #48bb78;
}

.para-with-image{
  display: flex;
}

#robot-image{
  height: 150px;
  margin-top: -85px;
  margin-right: 30px;
}

/* internship opportuties section */
.separator-text-container {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
  padding: 0 20px;
}
.separator-heading{
  font-family: 'OpenSansextrabold';
  font-size: 27px;
  color: #000000;
}
.separator-sub-text{
  font-family: 'OpenSans';
  font-weight: normal;
  font-style: normal;
  color: #636363;
  padding: 0px;
  margin-top: -12px;
}

.internships_button {
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 15px 30px;
  border: none;
  border-radius: 35px;
  background-color: rgb(155, 223, 70);
  font-size: large;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  position: relative;
}
.internships_button:hover{
  opacity: 0.8;
}

/* Internship cards */
.internship_card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-top:10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 80px;
  }
  
  
  .internship_card {
    flex: 0 0 calc(22% - 2px);
    height: 350px;
    background-color: #323E50;
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
    position: relative;
    cursor: pointer;
    transition: border-color 0.3s;
    margin-bottom: 20px;
    box-sizing: border-box;
  }
  
  /* Add hover effect styles */
  .internship_card:hover {
    border: 1.5px solid rgb(155, 223, 70); 
  }
  
  /* Change the heading color when hovering over the card */
  .internship_card:hover .internship_card-heading {
    color: rgb(155, 223, 70);
  }
  
  
  .internship_card-image-container {
    position: relative;
    height: 70px;
  }
  
  .internship_card-image {
    width: auto;
    height: 100%;
    object-fit: fill; 
    border-radius: 8px; 
    margin-bottom: 0px;
  }
  
  
  .internship_card-heading {
    font-size: 17px;
    font-family:'OpenSansextrabold';
    font-weight: lighter;
    color: #FFF0F0;
    transition: color 0.3s;
    margin-top:35px;
    height: 70px;
    width: 80%;
  }
  
  .internship_card-footer {
    display: flex;
    flex-direction: column;
    justify-content:left;
    align-items: left;
    margin-top: -20px;
  }
  
  .internship_card-message{
    font-size: 13px;
    font-family: 'OpenSans';
    color: #E0E0E0;
    font-weight: lighter;
    width: 100%;
    margin-top: 30px;
  }

  #start-now-button{
    width:50%;
    margin-left: 0px;
    padding: 6px;
    padding-top: 0px;  
    text-align: start;
    padding-left: 12px;
  }

  #start-now-button::after {
    content: ' →'; 
    font-family: 'Open Sans', sans-serif; /* Ensure the font-family is applied to the arrow as well */
    font-size: 18px;
    color: #FFFFFF;
    font-weight:bolder;
  }
  .lock-icon {
    font-size: 20px;
  }

  .internship_card-image_grey{
    width: 60%;
    height: 100px; 
    object-fit: fill; 
    border-radius: 8px;
    margin-bottom: 10px;
    filter: grayscale(100%);
  }


  /* get offer letter section  */
  .get-offer-letter-container{
    justify-content: center;
    margin: 0px;
    padding-bottom: 30px;
  }

.offer-letter-content{
  display: flex;
  justify-content: center;
  margin: 0px;
 
}

#offer-letter-heading-detail{
  width:20%;
  text-align: start;
  margin-right: 17%;
  margin-top: -48px;
  padding: 0 15px; 
}

.offer-letter-image-container{
  background-color: #1D2531;
  height: 165px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  margin: 10px 0;
}

.resume-image{
  height: 100%;
}

#offer-letter-buttons-container{
  justify-content: center;
  width: fit-content;
  margin-left: 23.3%;
  padding-top: 0px;
  margin-top: -23px;
}

#offer-letter-buttons-container button{
  border-radius: 10px;
  width:fit-content;
  margin-left: 12px;
  padding: 6px;
  padding-top: 11px;
  padding-bottom: 11px;  
  text-align: center;
  padding-left: 12px;
  background-color: #9BDF46;
  font-size: 13px;
  font-family: 'OpenSans';
  font-weight: bold;
}

/* Doubt Resolution section */
.doubts-resolution-container{
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top:10px;
  width: 100%;
}

.doubts-resolution-card-div{
  display: flex;
  justify-content: center;
}

#doubt-sub-text{
  margin-top: -30px;
}
#doubt-sub-text p{
  font-family: 'OpenSans';
  font-weight: normal;
  font-size: 15px;
}
#sentence-1{
  margin-bottom: -10px;
}

#doubt-sub-text a{
  text-decoration: none;
}

.doubt-resolution-card{
  flex: 0 0 calc(30% - 2px);
  height: 320px;
  width: 250px;
  background-color: #323E50;
  padding: 40px 40px 40px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  cursor: pointer;
  transition: border-color 0.3s;
  margin: 20px;
  box-sizing: border-box;
  justify-content: center;
}

.doubt-resolution-card-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.doubt-resolution-image-container{
  position: relative;
  background-color: #5A6776;
  border-radius: 8px; 
  height: 85px;
  width: fit-content;
  margin: 0 auto;
  padding: 5%;
}
.doubt-resolution-card-image{
height: 100%;
}

.doubt-resolution-card-heading{
  font-family: 'OpenSansextrabold';
  font-size: 17px;
  color: #FFFDFD;
  margin-bottom: 0px;
  letter-spacing: 0.7px;
}

.doubt-resolution-card-description{
  color:#C7C6C6 ;
  font-family: 'OpenSans';
  font-weight:bold;
  font-size: 14px;
  margin-top: 5px;
}

.doubt-resolution-card-button{
background-color: #9BDF46;
color: #FFF9F9;
font-family: 'OpenSans';
font-size: 14px;
font-weight: bold;
border: none;
border-radius:10px ;
padding: 10px;
padding-left: 15px;
padding-right: 15px;
margin-top: 15px;
}

@media (max-width: 768px) {

/* Mobile view: show one training card per row */
.card-container {
  justify-content: center;
  margin: 0;
}

.card {
  flex: 0 0 100%;
  margin: 0 0 20px;
}

.separator-text {
  margin: 10px;
  font-size: 18px;
}

.internships_button {
  padding: 8px 15px;
}

#start-training-button{
margin-left: -18px;
}

/* Mobile view: show one internship card per row */
.internship_card-container {
  justify-content: center;
  margin: 0;
}

.internship_card {
  flex: 0 0 100%;
  margin: 0 0 20px;
}

.no-trainings-message{
  margin-top:16px;
  margin-bottom:40px;
  height: fit-content;  
  width: auto ;
  margin-left: 5%;
  margin-right: 5%;
  padding: 15px;
}
.internship_card{
  height: fit-content;
}
.internship_card-heading{
  height: fit-content;
}
.internship_card-footer{
  margin-top: 0px;
  margin-top: -30px;
}
.get-offer-letter-container{
  padding-left: 30px;
  padding-right: 30px;
}

#offer-letter-heading-detail
{
width: fit-content;
margin-left: 35px;
margin-top: -30px;
}

#offer-letter-buttons-container{
  margin-top: 10px;
  display: flex;
  gap: 20;
  margin-left: -15px;
}

.offer-letter-image-container{
  height: 130px;
  margin-left: -30px;
  margin-right: 50px;
  margin-top: 0px;
}
.doubts-resolution-card-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0px;
}

.doubt-resolution-card{
margin-right: 20%;
margin-left: 20%;
}

#oops-title{
  font-size: 35px;
  margin-top: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

#para-1{
  font-size: 12px;
  margin-top: 2px;

}

#para-2{
  font-size: 10px;
  font-weight: 200;
}

#para-3{
  font-size: 10px;
  margin-bottom: -4px;
}
#user_email_in_msg{
  font: 'OpenSansextrabold';
}
#para-4{
  font-size: 10px;
  font-family:'OpenSans';
}
.whatsapp-button {
  font-size: 6px;
  padding: 4px;
  border-radius: 8px;
}

.para-with-image{
  display: flex;
}

#robot-image{
  height: 65px;
  margin-top:-5px;
  margin-right: 5px;
}

}

@media (min-width: 768px) and (max-width:863px){
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Distribute cards evenly in a row */
  margin-top: 20px;
}


.card {
  flex: 0 0 calc(25% - 2px); 
  background-color: rgb(247, 247, 247);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  cursor: pointer;
  transition: border-color 0.3s;
  margin-bottom: 20px;
  box-sizing: border-box;
}

}

/* Media query for mobile screens */
@media only screen and (max-width: 600px) {
  .doubts-resolution-container{
    display: block;
    margin-bottom: 0px;
  }

  
  
}

