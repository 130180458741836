/* Default styles */

.toggle-btn {
    margin-top: 31%;
    margin-left: 2%;
    cursor: pointer;
    display: block;
    position: fixed;
    z-index: 999;
    width: fit-content;
  }
  
  .line {
    width: 30px;
    height: 3px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
  }
  
  .faculty-sidebar {
    width: 240px; /* Adjust as needed */
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #f7f7f7;
    padding: 20px;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    transition: width 0.4s;
  }
  
  .faculty-sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .faculty-sidebar-item {
    padding: 12px;
    margin-bottom: 14px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: black;
  }
  
  /* Media Query for smaller screens */
  @media (max-width: 767px) {
  .toggle-btn {
    top: 10px;
    left: 10px;
  }

  .line {
    width: 20px;
    height: 2px;
  }

  .sidebar {
    width: 100%; /* Adjust for mobile view */
  }

  .sidebar.closed {
    width: 0;
  }

  .toggle-btn{transition: top 0.4s ease-out;
  }
   .toggle-btn{
    position: fixed;
   }
  .toggle-btn.fixed {
    position: fixed;
  }
  
}
  