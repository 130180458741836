/* StudentCard.css */

.student-card {
  width: 90%;
  margin-bottom: 30px;
  overflow: hidden;
  margin-left: 1%;
  margin-right: 1%;
  border-radius: 8px; /* Added border-radius */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added light shadow */
  transition: box-shadow 0.3s ease; /* Added smooth transition for box-shadow */
  position: relative;
}

.student-card:hover {
  border: 1px solid #7ED321;  /* Light green border color on hover */
}

.card-header {
  display: flex;
  justify-content:left;
  align-items: center;
  padding: 15px; 
  background-color: #f7f7f7;
  cursor: pointer;
  transition: padding 0.9s ease, box-shadow 0.9s ease;
}

.card-header:hover{
  color: #7ED321;
}

.arrow {
  font-size: 15px;
  margin-left: 40%;  
  position: absolute;
  right: 20px; 
}

.card-details {
  padding: 15px; 
  background-color:#f7f7f7;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.9s ease, padding 0.9s ease;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
  color: rgb(37, 37, 37);
}

.card-details div {
  margin-bottom: 12px;
  margin-left: 12px;
}

.student-serial {
  margin-right: 0%; 
  margin-top: 5px;
}

.student-name {
  font-size: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  transition: color 0.4s ease;
  margin-left: 2%;
}

.student-card.expanded {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.student-card.expanded .card-details {
  max-height: 500px;
  padding: 15px;
}

.student-details {
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
}

.student-details strong {
  margin-right: 5px;
}

.student-details .detail-content {
  margin-right: 10px;
  padding-top: 0px;
}


.training-progress-map {
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack items vertically */
  color: rgb(61, 60, 60);
}

  @media (max-width: 767px) {
    .student-card {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  
    .card-header {
      padding: 10px;
    }
  
    .card-details {
      padding: 10px;
    }

    .card-details {
      font-size: 13px;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      color:rgb(37, 37, 37);
      display: flex;
      flex-direction: column;
      overflow-y: auto; /* Enable vertical scrolling */
    }
    
    .card-details div {
      margin-bottom: 1px; /* Further reduced margin for mobile view */
    }
    
    .card-details div .student-details {
       margin-bottom: 15px;
       margin-top: 0px;
      margin-right: 0px;
    }
     
    .student-serial {
      margin-top: 5px; /* Add top margin for spacing */
    }
  
    .student-name {
      font-size: 16px;
    }
  
    .card-details svg {
      font-size: 20px;
    }

    .arrow {
      font-size: 16px; /* Reduced the size of the arrow for mobile view */
      margin-right: 10px; /* Increased the right margin for the arrow on mobile view */
    }

    .student-name {
      font-size: 17px;
      color: rgb(59, 59, 59);
  }

  .student-card.expanded .student-name {
    color: #7ED321; /* Light green color for selected state */
   }

  }