/* fonts */

@font-face {
  font-family: 'OpenSans';
  src: url('./fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.circular-progress-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 90px;
    height:90px;
    margin: 0px;
    padding: 0px;
    margin-top: -15px;
  }
  
  .circular-progress-container svg {
    transform: rotate(-90deg);
  }
  
  .progress-number {
    position: absolute;
    font-size: 13px; 
    font-family: 'OpenSans';
    font-weight: bold;
    text-align: center;
    margin-top: -2px;
  }

  @media only screen and (max-width: 600px) {
    .circular-progress-container {
        width: 70px; /* Further reduce width for smaller screens */
        height: 70px; /* Further reduce height for smaller screens */
    }

    .progress-number {
        font-size: 10px; /* Adjust font size for smaller screens */
    }
}