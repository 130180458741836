#root {
    height: 100vh;
    margin: 0;
    padding: 0;
  }
.iframe-content{
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100%;
    width:100%;
    
}

iframe {
  max-width: 100%; /* Ensure the iframe doesn't exceed the screen width */
  border: 0; /* Remove iframe border */
}

@media only screen and (max-width: 767px) {
  .iframe-content {
      overflow: auto; /* Allow both horizontal and vertical scrolling on smaller screens if needed */
      width: 138%;
      margin: 0;
      padding: 0;
  }
}
